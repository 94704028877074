.signup {
	background: #f1f1f1;
	min-height: 100vh;
	width: 100vw;
	
	.wrapper {
		width: 40vw;
		margin: 0 auto;
		padding-top: 4vh;
		text-align: center;

		.logo {
			vertical-align: middle;
			padding: 3%;

			img {max-width: 380px}
		}

		.form {
			h1 {
				font-weight: 800;
				font-size: 2.65em;
				color: $second;
			}

			.form-group {margin-bottom: 24px;}

			input, select {
				padding: 16px;
		    font-size: 1.25em;
		    height: 40px;
			}

			label {
		    float: none;
		    display: block;
		    text-align: left;
		    font-weight: 400;
		    font-size: 1.25em;
		    line-height: 1.15;
		    width: auto;
			}

			.tagline {
				color: $third;
				padding: 16px 8px;
				font-size: 1.25em;
			}

			.form-actions {
				button {width: 100%}

				.terms {padding: 16px 0}
			}
		}
	}

	.signup-footer {
		text-align: center;
		padding: 8px 16px;
		margin-top: 32px;
		color: $fourth;
	}
}
.sidebar {
	background: #f0f0f5;
    text-align: center;
    position: fixed;
    border: none;
	margin: 0;
    
    min-height: 110vh;
	height: 100vh;
	height: -webkit-calc(100% - 66px);
	height:         calc(100% - 66px);
	width: 14vw;
    
    @media screen and (max-width: 1000px) {
        position: fixed;
		min-height: 78px;
            height: 78px;
		display: block;
		width: 100vw;
		margin: 0;
        bottom: 0;
        right: 0;
    }

	.site-menubar {
		.site-menubar-header {
            padding: 24px;
            
            @media screen and (max-width: 1000px) {
                display: none;
            }

			img {max-width: 70%}
		}

		.site-menubar-body {
			height: 100%;
			height: -webkit-calc(100% - 76px);
            height:         calc(100% - 76px);
            
            @media screen and (max-width: 1000px) {
                display: inline-block;
                width: 76%;
            }

			.site-menu {
				padding: 0 0 24px 0;
				overflow-y: hidden;
				list-style: none;
				margin: 0;

				.site-menu-item {
					position: relative;
                    white-space: nowrap;
                    padding: 16px 24px;
                    display: block;
                    width: 100%;

                    @media screen and (max-width: 1000px) {
                        display: inline-block;
                        width: 25%;
                    }

                    &:hover {
                        background: #71C9DA;

                        .site-menu-icon, .site-menu-title {color: white}

                        a:hover {color: #fff}
                    }

                    .site-menu-icon {
                        display: inline-block;
                        font-size: 2.65em;
                        width: auto;
                        color: #FF9000;

                        @media screen and (max-width: 1000px) {
                            display: block;
	    				    font-size: 2.25em;
							width: auto;
                        }
                    }

                    .site-menu-title {
                        display: block;
                        width: auto;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #797979;

                        @media screen and (max-width: 1000px) {
                            display: none
                        }
                    }

					a {
						text-transform: uppercase;
						white-space: nowrap;
						cursor: pointer;
						font-weight: 500;
						display: block;
						color: #c3c6c9;

						&:hover {text-decoration: none; color: #FFF;}
					}
				}
			}
		}
	}
}
.login {
	background: #f1f1f1;
	min-height: 100vh;
	width: 100vw;

	.wrapper {
		width: 400px;
		margin: 0 auto;
		padding-top: 10vh;
		text-align: center;

		.logo,
		.form {
			display: block;
			width: auto
		}

		.logo {
			img {max-width: 300px}
		}

		h1 {
			font-weight: 800;
			font-size: 2.85em;
			color: $second;
		}

		h2 {
			font-size: 1.5em;
			font-weight: 400;
			margin: 24px 0;
			color: $second
		}

		.form {
			.form-group {margin-bottom: 24px;}

			input, select {
				padding: 16px;
		    font-size: 1.25em;
		    height: 40px;
			}

			label {
		    float: none;
		    display: block;
		    text-align: left;
		    font-weight: 400;
		    font-size: 1.25em;
		    line-height: 1.15;
		    width: auto;
			}

			.tagline {
				color: $third;
				padding: 16px 8px;
				font-size: 1.25em;
			}

			.form-actions {
				button {width: 100%}

				.terms {padding: 16px 0}
			}
		}
	}

	.login-footer {
		text-align: center;
		padding: 8px 16px;
		margin-top: 64px;
		color: $third;
	}
}
.full-page-loader {
    float: left;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

// .invoice-pagada,
// .invoice-porcobrar {
//     display: block;
//     width: auto;
//     text-align: center;
//     padding: 16px 12px;
// }

.invoice-pagada{
    min-width: 80px;
    padding: 0 10px !important;
    height: 25px;
    margin-right: 24px;
    line-height: 25px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    color: #67d2ab;
    text-align: center;
    background-color: #daf4eb;
}

.invoice-porcobrar {
    min-width: 80px;
    padding: 0 10px !important;
    height: 25px;
    margin-right: 24px;
    line-height: 25px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    color: #f79494;
    text-align: center;
    background-color: #fee4e5;
}

.graph-totals {
    padding: 10vh 0 0 0;
    text-align: center;

    span {
        font-size: 1.85em;
        font-weight: bold;
        color: #71C9DA;
    }

    strong {
        font-weight: normal;
        font-size: 1.2em
    }
}

.invoice-services-option {
    color: #FF9000;
}

.login {
    .logo {
        padding: 24px 0;
    }

    .form {
        h1 {
            padding: 24px 0;
        }
    }

    .login-signup {
        text-align: center;
    }

    .login-copyright {
        padding: 32px 0;
        color: #9a9a9b;
        text-align: center;
    }
}

.required-error {
    border: 1px solid red;
}

.btn {
    padding: 16px 24px;
}

.btn-secondary {
    background: #797979;
    border-color: #797979;
    opacity: 0.95;

    &:hover, &:active {
        background: #696969;
        border-color: #696969;
    }
}

.dropdown-toggle-icon {
    vertical-align: middle;
    padding: 16px !important;
    background: #2CBB84 url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") 50% 50% no-repeat;
}
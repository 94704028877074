.navbar-profile {
	margin-bottom: 12px;
	vertical-align: top;
    width: auto;

    @media screen and (max-width: 1000px) {
        top: 0;
        z-index: 500;
        width: 100vw;
        position: fixed;
        padding: 12px 24px;
        background: #f7f7f7;
    }

    .account-expired-banner {
        background: #BD3225;
        padding: 8px;
        color: #FFF;

        a { color: #ffd4a5 }
    }

    .navbar-timbrat-mobile {
        text-align: left;
        display: none;
        width: 40vw;

        img {width: 90px}

        @media screen and (max-width: 1000px) {
            display: inline-block;
        }
    }

    .navbar-profile-actions {
        text-align: right;

        @media screen and (max-width: 1000px) {
            display: inline-block;
            width: 46vw;
        }
    }

    .header-item {
        height: $header-height;
        box-shadow: none !important;
        color: $header-item-color;
        border: 0;
        border-radius: 0px;
        width: 200px;

        @media screen and (max-width: 1000px) {
            width: 80px;
        }

        &:hover {
            color: $header-item-color;
        }

        .header-profile-user {
            background-color: $gray-300;
            display: inline-block;
            height: 36px;
            width: 36px;
            padding: 3px;
            margin-right: 12px;
        }
    
        .header-item-profile-name {
            display: inline-block;
            vertical-align: top;
            padding-top: 8px;
            margin-right: 8px;

            @media screen and (max-width: 1000px) {
                display: none;
            }
        }

        .header-item-chevron {
            display: inline-block;
            vertical-align: top;
            padding-top: 8px;
        }
    }
}